// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-get-back-whips-js": () => import("./../../../src/pages/get-back-whips.js" /* webpackChunkName: "component---src-pages-get-back-whips-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ladies-js": () => import("./../../../src/pages/ladies.js" /* webpackChunkName: "component---src-pages-ladies-js" */),
  "component---src-pages-mens-js": () => import("./../../../src/pages/mens.js" /* webpackChunkName: "component---src-pages-mens-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-details-js": () => import("./../../../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */)
}

